import React from 'react'
import overlook from '../../static/img/overlook.jpg'

export const AppointmentSectionTemplate = () => {
  return (
    <div
      className="head-section"
      style={{
        backgroundImage: `url(${overlook})`,
        backgroundSize: 'cover',
        textAlign: 'center',
        backgroundPosition: '75% 75%',
      }}
    >
      <section
        className="hero"
        style={{
          paddingBottom: '10rem',
          paddingTop: '10rem',
        }}
      >
        <div className="hero-header">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section is-primary">
                <div className="appointment-button">
                  <a
                    children="Book Appointment"
                    className="button is-link is-outlined is-rounded is-medium"
                    href="//lisanelson.appointlet.com"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
